import React from 'react'
import './about.css'
import ME from '../../assets/Me_normal.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Krijg Meer Over Mij Te Weten</h5>
      <h2>Over Mij</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon' />
              <h5>Ervaring</h5>
              <small>3+ Jaar</small>
            </article>

            <article className="about__card">
              <FiUsers className='about__icon' />
              <h5>Klanten</h5>
              <small>18+</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className='about__icon' />
              <h5>Projecten</h5>
              <small>31 Compleet</small>
            </article>

          </div>
            <p>
              Mijn naam is Bart van Wijk en ik ben 18 jaartjes jong. <br />
              Ik ben zzp'er op het gebied van web development en ik heb een eigen bedrijf genaamd <a href="https://secweb.me">SecWeb</a>. <br />
              Hiernaast ben ik ook jr. ethical hacker en ben ik in te huren voor zaken als pentesting en netwerkscanning + rapportages. <br />
              Ook ben ik nog erg veel aan het leren <br /> <br />

              Ik ben natuurlijk niet al de tijd aan het programmeren, ik heb ook erg veel hobbies zoals schaken en lekker ontspannen.
              <br />
              Ik heb een versnelde MBO opleiding (Software Development) afgerond en ik heb gestudeerd voor HBO Informatica op <a href='https://www.hsleiden.nl/'>Hogeschool Leiden.</a><br />
              Hierdoor heb ik de mogelijkheid gekregen om nu een jaar in dienst te zijn bij SamsonIT als DevOpser.
              Naast mijn bezigheden als DevOpser ben ik hard aan het leren om mijn <a href="https://www.offsec.com/courses/pen-200/">OSCP</a> te halen.
            </p>

            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About