import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'
// import {FaTimes} from 'react-icons/fa'

const Services = () => {
  return (
    <section id='services'>
      <h5>Wat Heb Ik Te Bieden</h5>
      <h2>Diensten</h2>
      <h2>Alle Diensten Worden Geleverd Onder <a href="https://secweb.me" target='_blank' className='link-secweb'>
           SecWeb
        </a>
      </h2>

    <div className="container services__container">
      <article className="service">
        <div className="service__head">
          <h3>Webapplicatie Onderhouden</h3>
        </div>

        <ul className="service__list">
          <li>
            <BiCheck className='service__list_icon' />
            <p>X Aantal Uur Per Week Bezig</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Beschikbaar Voor Laravel / React.JS / PHP / Wordpress</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Alleen Beschikbaar Voor Bestaand & Werken Systeem</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Goede & Snelle Communicatie</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Flexibele Werkuren</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>7 Dagen Per Week Bereikbaar <br />(Niet Beschikbaar!)</p>
          </li>
          <li>
            <button className="btn btn-primary">
              <a className='' href="#contact">
                € 30.00 per uur
              </a>
            </button>
          </li>
        </ul>
      </article>

      <article className="service">
        <div className="service__head">
          <h3>Webapplicatie Bouwen</h3>
        </div>

        <ul className="service__list">
          <li>
            <BiCheck className='service__list_icon' />
            <p>X Aantal Uur Per Week Bezig</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Beschikbaar Voor Zowel Dynamisch Als Statisch</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Geen Gebruik Van Wordpress</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Compleet Custom Webapplicatie</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Bouw Ontwerpen Voor Het Bouwen</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Goede & Snelle Communicatie</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>7 Dagen Per Week Bereikbaar <br /> (Niet Beschikbaar!)</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Mogelijkheid Om Tarief Te Veranderen</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Weeklijkse Updates Tijdens Het Bouwen Van Het Project</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Bouw Webapplicaties Voor Alle Categorieën <br /> (Horeca, Bedrijven, Portfolio's Etc.)</p>
          </li>
          <li>
            <button className="btn btn-primary">
              <a className='' href="#contact">
                € 36.00 per uur
              </a>
            </button>
          </li>
        </ul>
      </article>

      <article className="service">
        <div className="service__head">
          <h3>Pentesting / Netwerk Analyse</h3>
        </div>

        <ul className="service__list">
          <li>
            <BiCheck className='service__list_icon' />
            <p>Beschikbaar voor avond / nacht</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Uitgebreide en gedetailleerde rapportages</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Beschikbaar voor het vooraf nemen van backups zonder bijbetaling</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Complete transparantie in kwetsbaarheden</p>
          </li>
          <li>
            <BiCheck className='service__list_icon' />
            <p>Follow-up service beschikbaar (Naar dienst "Webapplicatie Onderhouden")</p>
          </li>
          <li>
            <button className="btn btn-primary">
              <a className='' href="#contact">
                € 60.00 per uur
              </a>
            </button>
          </li>
        </ul>
      </article>
    </div>

    </section>
  )
}

export default Services